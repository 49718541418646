<template>
  <div>
    <CCardBody>
        
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :sorter='{ external: true, resetable: true }'
        :items-per-page="small ? 5 : overflow"
        :dark="dark"
        pagination
       
      >
      </CDataTable>
    </CCardBody>
  </div>
</template>

<script>
export default {
  name: 'GraphTable',
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'GraphTable'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
}
</script>
